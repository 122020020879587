import styles from "../../styles/Home.module.css";
import NavBar from "../../components/Navigation/NavBar";
import Promo from "../../components/Promo/Promo";
import Benefit from "../../components/Benefit";
import ContactForm from "../../components/ContactForm/ContactForm";
import QuestVer2 from "../../components/QuestVer2/QuestVer2";
import Footer from "../../components/Footer";
import {state} from "../../projectData/state";
import InfoCard from "../../components/Info_card/InfoCard";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";


const props = state.pages.home

const HomePage = () => {
  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = `//www.forapay.io`; 
    navigate(path);
  }

    return (
      <>
        <head>
          <title>ForaPay</title>
          <meta
            name="description"
            content="We are working with personal and business clients around the World as a licensed digital Fintech company (e.g. digital bank)"
          />
          <base href="/" />
          <link rel="shortcut icon" href={"/Images/favicon.ico"} />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/Images/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/Images/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/Images/favicon-16x16.png"
          />
        </head>
        <div className={styles.container}>
          <main className={styles.main}>
            <div className={styles.block1}>
              <NavBar />
              <Promo
                className={styles.promo}
                title={props.promo.title}
                text={props.promo.text}
                img={"/Images/Digital Wallet.svg"}
              />
            </div>
            <div className={styles.block2} id="block2">
              <div className={styles.block2_content}>
                <Typography textAlign="center" variant="h2">
                  Simple, secure money transfers
                </Typography>
                <div className={styles.meta}>
                  <div>
                    <Benefit
                      title={props.benefits.benefit1.title}
                      text={props.benefits.benefit1.text}
                      img={props.benefits.benefit1.img}
                      textBack={props.benefits.benefit1.textBack}
                    />
                  </div>
                  <div>
                    <Benefit
                      title={props.benefits.benefit2.title}
                      text={props.benefits.benefit2.text}
                      img={props.benefits.benefit2.img}
                      textBack={props.benefits.benefit1.textBack}
                    />
                  </div>
                  <div>
                    <Benefit
                      title={props.benefits.benefit3.title}
                      text={props.benefits.benefit3.text}
                      img={props.benefits.benefit3.img}
                      textBack={props.benefits.benefit1.textBack}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.block3}>
              <div className={styles.block3_content}>
                <div>
                  <InfoCard
                    text={props.infoCards.infoCardBoarding.text}
                    title={props.infoCards.infoCardBoarding.title}
                    img={props.infoCards.infoCardBoarding.img}
                    flexDirection="row"
                  />
                </div>
                <div>
                  <InfoCard
                    text={props.infoCards.infoCardTransfers.text}
                    title={props.infoCards.infoCardTransfers.title}
                    img={props.infoCards.infoCardTransfers.img}
                    flexDirection="row-reverse"
                  />
                </div>
                <div>
                  <InfoCard
                    text={props.infoCards.infoCardCurrency.text}
                    title={props.infoCards.infoCardCurrency.title}
                    img={props.infoCards.infoCardCurrency.img}
                    flexDirection="row"
                  />
                </div>
                <div>
                  <InfoCard
                    text={props.infoCards.infoCardAcquiring.text}
                    title={props.infoCards.infoCardAcquiring.title}
                    img={props.infoCards.infoCardAcquiring.img}
                    flexDirection="row-reverse"
                  />
                </div>
                <div>
                  <InfoCard
                    text={props.infoCards.forapayCrypto.text}
                    title={props.infoCards.forapayCrypto.title}
                    img={props.infoCards.forapayCrypto.img}
                    action={routeChange}
                    flexDirection="row"
                  />
                </div>
              </div>
            </div>
            {/* <div className={styles.block4}>
              <div className={styles.block4_content}>
                <div className={styles.title4}>{props.managing.title}</div>
                <div className={styles.desc4}>
                  <div>{props.managing.text1}</div>
                  <div>{props.managing.text2}</div>
                </div>
              </div>
            </div> */}
            <div className={styles.contactForm__wrapper} id={"contactForm"}>
              <Typography textAlign="center" variant="h1" mb="1rem">
                Have a question?
              </Typography>
              <ContactForm
                title={props.form.title}
                text={props.form.text}
                img={props.form.img}
                flexDirection="row"
              />
            </div>
            <div className={styles.block6}>
              <div className={styles.block6_content}>
                <div className={styles.q_card}>
                  <Typography textAlign="left" variant="h1" mt="1rem" mb="2rem">
                    Popular questions
                  </Typography>
                  <QuestVer2 />
                </div>
              </div>
            </div>
          </main>
          <Footer image="/logoSmall.svg" />
        </div>
      </>
    );
}

export default HomePage;