import styles from './NavBar.module.css'
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Typography } from '@mui/material';


const NavLinks = (props) => {
    const navigate = useNavigate();
    const user = useSelector((state) => state.user);

    const handleClick = () => {
        if (props.isMobile) {
          props.closeMobileMenu();
        }
    
        const currentURL = window.location.pathname;
        if (currentURL === '/') {
          scrollToSection('contactForm');
        } else {
          navigate('/');
          window.onload = () => {
            scrollToSection('contactForm');
          };
        }
      };
    
      const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
          window.scrollTo({
            behavior: 'smooth',
            top: section.offsetTop,
          });
        }
      };

    return (
      <ul className={styles.navbarLine}>
        <li onClick={() => props.isMobile && props.closeMobileMenu()}>
          <div onClick={() => navigate("/")} className={styles.logo}>
            <div className={styles.menu__link}>Home</div>
            <div>
              <img
                className={styles.logoImage}
                src={"/logoBlack.svg"}
                alt={"logo"}
                width={150}
                height={40}
              />
            </div>
          </div>
        </li>
        <li>
          <ul className={styles.menu__list}>
            <li onClick={() => props.isMobile && props.closeMobileMenu()}>
              <div
                onClick={() => navigate("/products")}
                className={styles.menu__link}
              >
                <Typography variant='h5'>
                  Get Started
                </Typography>
              </div>
            </li>
            <li onClick={() => props.isMobile && props.closeMobileMenu()}>
              <div
                onClick={() => navigate("/personal")}
                className={styles.menu__link}
              >
                <Typography variant='h5'>
                  Personal
                </Typography>
              </div>
            </li>
            <li onClick={() => props.isMobile && props.closeMobileMenu()}>
              <div
                onClick={() => navigate("/business")}
                className={styles.menu__link}
              >
                <Typography variant='h5'>
                  Business
                </Typography>
              </div>
            </li>
            <li onClick={() => props.isMobile && props.closeMobileMenu()}>
              <div
                onClick={() => navigate("/about")}
                className={styles.menu__link}
              >
                <Typography variant='h5'>
                About Us
                </Typography>
              </div>
            </li>
            <li onClick={handleClick}>
              <button data-goto="contact_bnt" className={styles.contact_bnt}>
                Contact Us
              </button>
            </li>
            <li onClick={() => props.isMobile && props.closeMobileMenu()}>
              <div
                onClick={() => navigate(user ? `/profile/${user._id}` : "/reg")}
              >
                <button data-goto="contact_bnt" className={styles.contact_bnt}>
                  Log In
                </button>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    );
}

export default NavLinks;