import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import HomePage from "./scenes/homePage";
import LoginPage from "./scenes/loginPage";
import ProfilePage from "./scenes/profilePage";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";
import { themeSettings } from "./theme";
import ProductsPage from "./pages/Products";
import About from "./pages/About";
import Business from "./pages/Business";
import PersonalPage from "./pages/Personal";

function App() {
    const mode = useSelector((state) => state.mode);
    const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
    const isAuth = Boolean(useSelector(state => state.token));

  return (
    <div className="app">
      <BrowserRouter>
          <ThemeProvider theme={theme}>
              <CssBaseline />
              <Routes>
                  <Route path={'/'} element={ <HomePage />} />
                  <Route path={'/reg'} element={ <LoginPage /> }/>
                  <Route path={'/about'} element={ <About /> }/>
                  <Route path={'/products'} element={<ProductsPage />}/>
                  <Route path={'/business'} element={<Business />}/>
                  <Route path={'/personal'} element={<PersonalPage />}/>
                  <Route path={'/profile/:userId'} element={isAuth ? <ProfilePage /> : <Navigate to="/reg" /> }/>
                  <Route path={'/componentTesting'} element={<ProfilePage />}/>
              </Routes>
          </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
