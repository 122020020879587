import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    mode: "light",
    user: null,
    token: null,
    posts: [],
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setMode: (state) => {
            state.mode = state.mode === "light" ? "light" : "dark" ;
        },
        setLogin: (state, { payload: { user, token } }) => {
            state.user = user;
            state.token = token;
        },
        setLogout: (state) => {
            state.user = null;
            state.token = null;
        },
        setFriends: (state, { payload: { friends } }) => {
            if (state.user) {
                state.user.friends = friends;
            } else {
                console.log("user friends non-existent :(");
            }
        },
        setPosts: (state, { payload: { posts } }) => {
            state.posts = posts;
        },
        setPost: (state, { payload: { post } }) => {
            const index = state.posts.findIndex((p) => p._id === post._id);
            if (index !== -1) {
                state.posts[index] = post;
            }
        },
    },
});

export const {
    setMode,
    setLogin,
    setLogout,
    setFriends,
    setPosts,
    setPost,
} = authSlice.actions;

export default authSlice.reducer;
