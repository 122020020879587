import * as yup from "yup";
import {useRef, useState} from "react";
import emailjs from "@emailjs/browser";
import {Box, Button, MenuItem, Select, TextField, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Formik} from "formik";

const contactFormSchema = yup.object().shape({
    email: yup.string().email("invalid email").required("required"),
    name: yup.string().required("required"),
    phone: yup.string().required("required"),
    message: yup.string(),
});

const initialValuesContactForm = {
    name: "",
    phone: "",
    email: "",
    message: "",
};

const ContactForm = (props) => {

    const form = useRef();
    const [result, showResult] = useState(false);

    const sendEmail = () => {
        emailjs.sendForm('service_8h4xqxe', 'template_pukz8wg', form.current, '0-ir_7k8VecDU9f_A')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    };

    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [countryCode, setCountryCode] = useState("+1");

    const handleCountryCodeChange = (event) => {
        setCountryCode(event.target.value);
    };

    const handlePhoneChange = (value) => {
        return countryCode + value;
    };

    const handleSubmitForm = (values, onSubmitProps) => {
        values.phone = handlePhoneChange(values.phone)
        sendEmail()
        onSubmitProps.resetForm();
        showResult(true);
    }

    const theme = useTheme();
    const {palette} = useTheme();
    const alt = theme.palette.background.alt;


    return (
        <Box>
            <Box
                width="100%"
                padding={isNonMobile ? '1rem 4rem' : '1rem'}
                gap="0.5rem"
                justifyContent="center"
            >

                <Box
                    display="flex"
                    flexDirection={isNonMobile ? props.flexDirection : "column"}
                    justifyContent="center"
                    gap="1rem"
                    backgroundColor={alt}
                    borderRadius="35px"
                    boxShadow="0 0 15px rgba(0, 0, 0, 0.2)"
                >
                    <Box
                        m="20px"
                        width={isNonMobile ? "50%" : undefined}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        borderRadius="40px"
                        boxShadow="0 0 15px rgba(187, 187, 187, 0.3) inset"
                        overflow="hidden"
                    >
                        <img src={props.img} alt="Currency exchange" style={
                            {
                                maxWidth: '100%',
                                height: "auto",

                            }
                        }/>
                    </Box>
                    <Box
                        p={isNonMobile ? "4rem 4rem" : "0 2rem"}
                        width={isNonMobile ? "50%" : undefined}
                    >
                        <Typography variant="h1" mb="1rem">
                            {props.title}
                        </Typography>
                        <Typography variant="h5" mb="1rem">
                            {props.text}
                        </Typography>

                        <Formik
                            onSubmit={handleSubmitForm}
                            initialValues={initialValuesContactForm}
                            validationSchema={contactFormSchema}

                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleBlur,
                                  handleChange,
                                  handleSubmit,
                                  resetForm,
                              }) => (
                                <form onSubmit={handleSubmit} ref={form}>
                                    <Box
                                        display="grid"
                                        gap="20px"
                                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                        sx={{
                                            "& > div": {gridColumn: isNonMobile ? undefined : "span 4"},
                                        }}
                                    >
                                        <TextField
                                            label="Name"
                                            onBlur={handleBlur}
                                            onChange={(event) => {
                                                handleChange({
                                                    ...event,
                                                    target: {name: 'name', value: event.target.value}
                                                });
                                            }}
                                            value={values.name}
                                            name="from_name"
                                            error={Boolean(touched.name) && Boolean(errors.name)}
                                            helperText={touched.name && errors.name ? errors.name : ''}
                                            sx={{gridColumn: "span 2"}}/>

                                        <TextField
                                            label="Email"
                                            onBlur={handleBlur}
                                            onChange={(event) => {
                                                handleChange({
                                                    ...event,
                                                    target: {name: 'email', value: event.target.value}
                                                });
                                            }}
                                            value={values.email}
                                            name="user_email"
                                            error={Boolean(touched.email) && Boolean(errors.email)}
                                            helperText={touched.email && errors.email ? errors.email : ''}
                                            sx={{gridColumn: "span 2"}}/>
                                        <Select
                                            value={countryCode}
                                            onChange={handleCountryCodeChange}
                                            sx={{gridColumn: "span 1 !important"}}
                                        >
                                            <MenuItem value="+1">+1</MenuItem>
                                            <MenuItem value="+44">+44</MenuItem>
                                            <MenuItem value="+61">+61</MenuItem>
                                            {/* Add more country codes here */}
                                        </Select>
                                        <TextField
                                            label="Phone"
                                            onBlur={handleBlur}
                                            onChange={(event) => {
                                                handleChange({
                                                    ...event,
                                                    target: {name: 'phone', value: event.target.value},
                                                });
                                            }}
                                            value={values.phone.replace(countryCode, "")}
                                            name="user_phone"
                                            error={Boolean(touched.phone) && Boolean(errors.phone)}
                                            helperText={touched.phone && errors.phone ? errors.phone : ""}
                                            sx={{gridColumn: "span 3 !important"}}
                                        />
                                        <TextField
                                            label="How can we help?"
                                            onBlur={handleBlur}
                                            onChange={(event) => {
                                                handleChange({
                                                    ...event,
                                                    target: {name: 'message', value: event.target.value}
                                                });
                                            }}
                                            value={values.message}
                                            name="users_message"
                                            error={Boolean(touched.message) && Boolean(errors.message)}
                                            helperText={touched.message && errors.message ? errors.message : ''}
                                            sx={{gridColumn: "span 4"}}/>

                                        <Button
                                            type="submit"
                                            sx={{
                                                mt: '1rem',
                                                mb: isNonMobile ? '1rem ' : '2rem',
                                                p: "1rem 3rem",
                                                borderRadius: "20px",
                                                backgroundColor: palette.primary.main,
                                                color: palette.background.alt,
                                                "&:hover": {color: palette.primary.main},
                                            }}
                                        >
                                            Send
                                        </Button>
                                    </Box>
                                    {result &&
                                        (
                                            <Box
                                                mt="10px"
                                            >
                                                <Typography vatiant="h5" >
                                                    Your message has been successfully sent!
                                                </Typography>
                                            </Box>
                                        )}
                                </form>
                            )}
                        </Formik>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default ContactForm;
