import styles from "./about.module.css";
import Footer from "../../components/Footer";
import Product_promo from "../../components/Product_promo/product_promo";
import NavBar from "../../components/Navigation/NavBar";
import Promo from "../../components/Promo/Promo";
import { state } from "../../projectData/state";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import Benefit from "../../components/Benefit";
import InfoCard from "../../components/Info_card/InfoCard";

const props = state.pages.aboutUs;

const AboutPage = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const theme = useTheme();
  const { palette } = useTheme();
  const alt = theme.palette.background.alt;

  return (
    <>
      <head>
        <title>ForaPay | About Us</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="icon" href="/fora-pay-ca-finalisation/public/favicon.ico" />
        <base href="/" />
      </head>
      <div className={styles.container}>
        <div className={styles.main}>
          <div className={styles.block1}>
            <NavBar className={styles.navbar} />
            <Promo
              title={props.promo.title}
              text={props.promo.text}
              img={props.promo.img}
            />
          </div>
          <div className={styles.block2}>
            <div className={styles.block2_content}>
            <Box
                
                sx={{
                    p: "10px 0",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "30px",
                  boxShadow: "0 4px 16px rgba(1, 17, 17, 0.25)",
                  "& > div": { m: "2rem" },
                  maxWidth: '698px',
                  width: isNonMobile ? "50%" : undefined,
                }}
              >
                <Box>
                  <Typography variant="h4">{props.howWeAre}</Typography>
                </Box>
              </Box>
              <main className={styles.mainContent}>
                <div className={styles.photoAbout1}>
                  <img
                    src={props.bgImg.img1}
                    alt="about1"
                    width={563}
                    height={402}
                  />
                </div>
                <div className={styles.historyCard}>
                  <Typography textAlign="left" variant="h2" mb="15px">
                    {props.ourAim.title}
                  </Typography>
                  <Typography textAlign="left" variant="h4">
                    {props.ourAim.text}
                  </Typography>
                </div>
                <div className={styles.partnersCard}>
                <Typography textAlign="left" variant="h2" mb="15px">
                {props.partners.title}
                  </Typography>
                  <Typography textAlign="left" variant="h4">
                  {props.partners.text}
                  </Typography>
                  <div className={styles.partnersList}>
                    <div>
                      <img
                        src={props.partners.logos.misolla}
                        alt="logo"
                        width={150}
                        height={54}
                      />
                    </div>
                    {/* <div>
                      <img
                        src={props.partners.logos.mc}
                        alt="Mastercard"
                        width={69}
                        height={54}
                      />
                    </div>
                    <div>
                      <img
                        src={props.partners.logos.visa}
                        alt="Visa"
                        width={167}
                        height={54}
                      />
                    </div> */}
                  </div>
                </div>
                <div className={styles.photoAbout2}>
                  <img
                    src={props.bgImg.img2}
                    alt="about 2"
                    width={563}
                    height={563}
                  />
                </div>
              </main>
            </div>
          </div>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="1rem"
            mt="40px"
            p="30px"
          >
            <Typography variant="h1">Our Team</Typography>
            <Box
              display="flex"
              flexDirection={isNonMobile ? "row" : "column"}
              gap="30px"
            >
              {/* Person 1 */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "50px",
                  boxShadow: "0 4px 16px rgba(1, 17, 17, 0.25)",
                  "& > div": { m: "2rem" },
                }}
              >
                <Box borderRadius="1000px" overflow="hidden">
                  <img
                    src={props.ourTeam.person1.img}
                    alt="logo"
                    width="200px"
                    style={{
                      borderRadius: "1000px",
                    }}
                  />
                </Box>
                <Box>
                  <Typography variant="h3">
                    {props.ourTeam.person1.name}
                  </Typography>
                  <Typography variant="h4">
                    {props.ourTeam.person1.role}
                  </Typography>
                </Box>
              </Box>
              {/* Person 2 */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "50px",
                  boxShadow: "0 4px 16px rgba(1, 17, 17, 0.25)",
                  "& > div": { m: "2rem" },
                }}
              >
                <Box borderRadius="1000px" overflow="hidden">
                  <img
                    src={props.ourTeam.person2.img}
                    alt="logo"
                    width="200px"
                    style={{
                      borderRadius: "1000px",
                    }}
                  />
                </Box>
                <Box>
                  <Typography variant="h3">
                    {props.ourTeam.person2.name}
                  </Typography>
                  <Typography variant="h4">
                    {props.ourTeam.person2.role}
                  </Typography>
                </Box>
              </Box>
              {/* Person 3 */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "50px",
                  boxShadow: "0 4px 16px rgba(1, 17, 17, 0.25)",
                  "& > div": { m: "2rem" },
                }}
              >
                <Box overflow="hidden">
                  <img
                    src={props.ourTeam.person3.img}
                    alt="logo"
                    width="200px"
                    style={{
                      borderRadius: "1000px",
                    }}
                  />
                </Box>
                <Box>
                  <Typography variant="h3">
                    {props.ourTeam.person3.name}
                  </Typography>
                  <Typography variant="h4">
                    {props.ourTeam.person3.role}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box mb="8rem" />
          <div className={styles.block3}>
            <div className={styles.block3_content}>
              <div className={styles.productCard}>
                <Product_promo 
                title="Fpay Platinum"
                image="/Images/ForaPayDebitCard.svg" />
              </div>
            </div>
          </div>
          <Footer image="/logoSmall.svg" />
        </div>
      </div>
    </>
  );
};
export default AboutPage;
