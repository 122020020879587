import styles from "./Benefit.module.css";
import React, {useState} from "react";
import {Box, Typography} from "@mui/material";


const Benefit = (props) => {
    const [isFlipped, setIsFlipped] = useState(false);

    const handleMouseEnter = () => {
        setIsFlipped(true);
    };

    const handleMouseLeave = () => {
        setIsFlipped(false);
    };

    return (
        <Box
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {!isFlipped ? (
                <Box
                    className={`${styles.front} ${isFlipped ? styles.back : ""}`}
                    sx={{
                        height: "365px",
                    }}
                >
                    <div className={styles.pic}>
                        <img src={props.img} alt="Icon" width={120} height={106}/>
                    </div>
                    <Typography variant="h3" className={styles.heading}>{props.title}</Typography>
                    <Typography variant="h5" className={styles.desc}>{props.text}</Typography>
                </Box>
            ) : (
                <Box className={`${styles.front} ${isFlipped ? styles.back : ""}`}
                     sx={{
                         height: "365px",
                     }}
                >
                    <Typography variant="h3" pt="55px" className={styles.headingBack}>{props.title}</Typography>
                    <Typography variant="h5" className={styles.desc}>{props.textBack}</Typography>
                </Box>
            )}
        </Box>
    );
};

export default Benefit;