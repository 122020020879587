import styles from "./product_promo.module.css";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const Product_promo = (props) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const { palette } = useTheme();
  const alt = theme.palette.background.alt;

  return (
    <Box>
      <Box
        width="100%"
        padding={isNonMobile && !props.fullWidth ? "1rem 4rem" : "1rem"}
        gap="0.5rem"
        justifyContent="center"
        textAlign="left"
      >
        <Box
          display="flex"
          flexDirection={isNonMobile ? props.flexDirection : "column"}
          justifyContent="center"
          alignItems="stretch"
          gap="1rem"
          backgroundColor={alt}
          borderRadius="35px"
          boxShadow="0 0 15px rgba(0, 0, 0, 0.2)"
        >
          <Box
            m="20px"
            width={isNonMobile && !props.fullWidth ? "50%" : undefined}
            display="flex"
            justifyContent="center"
            borderRadius="40px"
            boxShadow="0 0 15px rgba(187, 187, 187, 0.3) inset"
            overflow="hidden"
          >
            <img
              src={props.image}
              alt="Currency exchange"
              style={{
                maxWidth: "100%",
                height: "auto",
              }}
            />
          </Box>
          <Box
            p={isNonMobile && !props.fullWidth ? "8rem 4rem" : "0 2rem"}
            width={isNonMobile && !props.fullWidth ? "50%" : undefined}
          >
            <Typography variant="h1" mb="1rem">
              {props.title}
            </Typography>
            <Typography variant="h5">
              <ul className={styles.benefit__list}>
                <li className={styles.benefit__list_item}>
                  <div>
                    <img
                      src={"/percent.svg"}
                      alt={"icon"}
                      height={25}
                      width={25}
                    />
                  </div>
                  <div>No account fees</div>
                </li>
                <li className={styles.benefit__list_item}>
                  <div>
                    <img
                      src={"/cashBack.svg"}
                      alt={"icon"}
                      height={25}
                      width={25}
                    />
                  </div>
                  <div>Cash back</div>
                </li>
                <li className={styles.benefit__list_item}>
                  <div>
                    <img
                      src={"/virtualCardIcon.svg"}
                      alt={"icon"}
                      height={25}
                      width={25}
                    />
                  </div>
                  <div>Virtual card</div>
                </li>
                <li className={styles.benefit__list_item}>
                  <div>
                    <img
                      src={"/btcIcon.svg"}
                      alt={"icon"}
                      height={25}
                      width={25}
                    />
                  </div>
                  <div>Crypto wallet</div>
                </li>
              </ul>
            </Typography>
            <Button
              type="button"
              sx={{
                m: "2rem 0",
                p: "1rem 2rem",
                borderRadius: "20px",
                backgroundColor: palette.primary.main,
                color: palette.background.alt,
                "&:hover": { color: palette.primary.main },
              }}
            >
              Learn more
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Product_promo;
