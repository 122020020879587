import styles from "./crypto.module.css";
import Footer from "../../components/Footer";
import Product_promo from "../../components/Product_promo/product_promo";
import NavBar from "../../components/Navigation/NavBar";
import Promo from "../../components/Promo/Promo";
import { Box, Typography } from "@mui/material";
import InfoCard from "components/Info_card/InfoCard";
import { useState } from "react";
import { state } from "../../projectData/state";

const props = state.pages.personal;

const PersonalPage = () => {
  return (
    <>
      <head>
        <title>ForaPay | Crypto</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="icon" href="/fora-pay-ca-finalisation/public/favicon.ico" />
        <base href="/" />
      </head>
      <div className={styles.container}>
        <div className={styles.main}>
          <div className={styles.block1}>
            <NavBar className={styles.navbar} />
            <Promo
              title={props.promo.title}
              text={props.promo.text}
              img={props.promo.img}
            />
          </div>
          <div className={styles.block2} id={"block2"}>
            <div className={styles.block2_content}>
              <div>
                <InfoCard
                  text={props.main.everydayBanking.text}
                  title={props.main.everydayBanking.title}
                  img={props.main.everydayBanking.img}
                  meta={props.main.everydayBanking.meta}
                  flexDirection="row"
                />
              </div>
              <div>
                <InfoCard
                  text={props.main.paments.text}
                  title={props.main.paments.title}
                  img={props.main.paments.img}
                  meta={props.main.paments.meta}
                  flexDirection="row-reverse"
                />
              </div>
              <div>
                <InfoCard
                  text={props.main.investments.text}
                  title={props.main.investments.title}
                  img={props.main.investments.img}
                  meta={props.main.investments.meta}
                  flexDirection="row"
                />
              </div>
              <Typography textAlign="center" variant="h2">
                One card - endless possibilities
              </Typography>
              <div className={styles.productCard}>
                <Product_promo
                  text="Fpay Personal"
                  title="Currency exchange"
                  image="/Images/ForaPay Business card.svg"
                />
              </div>
            </div>
          </div>
        </div>
        <Footer image="/logoSmall.svg" />
      </div>
    </>
  );
};

export default PersonalPage;
