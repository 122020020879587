import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Benefit from "components/Benefit/Benefit";

const InfoCard = (props) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const { palette } = useTheme();
  const alt = theme.palette.background.alt;
  const [isVisible, setIsVisible] = useState(false);

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  return (
    <Box>
      <Box
        width="100%"
        padding={isNonMobile ? "1rem 4rem" : "1rem"}
        gap="0.5rem"
        justifyContent="center"
      >
        <Box
          display="flex"
          flexDirection={isNonMobile ? props.flexDirection : "column"}
          justifyContent="center"
          alignItems="stretch"
          gap="1rem"
          backgroundColor={alt}
          borderRadius="35px"
          boxShadow="0 0 15px rgba(0, 0, 0, 0.2)"
        >
          <Box
            m="20px"
            width={isNonMobile ? "50%" : undefined}
            display="flex"
            justifyContent="center"
            borderRadius="40px"
            boxShadow="0 0 15px rgba(187, 187, 187, 0.3) inset"
            overflow="hidden"
            p="15px"
          >
            <img
              src={props.img}
              alt="Currency exchange"
              style={{
                maxWidth: "100%",
                height: "auto",
              }}
            />
          </Box>
          <Box
            p={isNonMobile ? "8rem 4rem" : "0 2rem"}
            width={isNonMobile ? "50%" : undefined}
          >
            <Typography variant="h1" mb="1rem">
              {props.title}
            </Typography>
            <Typography variant="h5">{props.text}</Typography>
            <Button
              type="button"
              sx={{
                m: "2rem 0",
                p: "1rem 2rem",
                borderRadius: "20px",
                backgroundColor: palette.primary.main,
                color: palette.background.alt,
                "&:hover": { color: palette.primary.main },
              }}
              onClick={props.action ? props.action : handleClick}
            >
              Learn more
            </Button>
          </Box>
        </Box>
        {/* Content of the InfoCard that should appear with the animation */}
        {props.meta && (
          <Box
            sx={{
              display: "flex",
              flexDirection: `${isNonMobile ? "row" : "column"}`,
              justifyContent: `${isNonMobile ? "space-between" : "center"}`,
              opacity: isVisible ? 1 : 0,
              alignItems: `${isNonMobile ? "stretch" : "center"}`,
              maxHeight: isVisible ? "1000px" : 0,
              transition: "opacity 1s ease-out, max-height 1s ease-out",
              gap: "15px",
              pointerEvents: `${isNonMobile ? "auto" : "none"}`,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "367px",
                maxHeight: "389px",
                rowGap: "30px",
                textAlign: "center",
                backgroundColor: "#FFFFFF",
                borderRadius: "50px",
                boxShadow: "0 4px 16px rgba(1, 17, 17, 0.25)",
                marginTop: "68px",
                padding: "20px",
              }}
            >
              <Typography variant="h3" pt="55px">
                {props.meta.meta1.title}
              </Typography>
              <Typography variant="h5">{props.meta.meta1.text}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "367px",
                maxHeight: "389px",
                rowGap: "30px",
                textAlign: "center",
                backgroundColor: "#FFFFFF",
                borderRadius: "50px",
                boxShadow: "0 4px 16px rgba(1, 17, 17, 0.25)",
                marginTop: "68px",
                padding: "20px",
              }}
            >
              <Typography variant="h3" pt="55px">
                {props.meta.meta2.title}
              </Typography>
              <Typography variant="h5">{props.meta.meta2.text}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "367px",
                maxHeight: "389px",
                rowGap: "30px",
                textAlign: "center",
                backgroundColor: "#FFFFFF",
                borderRadius: "50px",
                boxShadow: "0 4px 16px rgba(1, 17, 17, 0.25)",
                marginTop: "68px",
                padding: "20px",
              }}
            >
              <Typography variant="h3" pt="55px">
                {props.meta.meta3.title}
              </Typography>
              <Typography variant="h5">{props.meta.meta3.text}</Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default InfoCard;
