import {useState} from "react";
import styles from './QuestVer2.module.css'

const QuestVer2 = (prop) => {
    const [selected, setSelected] = useState(null)

    const toggle = i => {
        if (selected === i) {
            return setSelected(null)
        }
        setSelected(i);
    };

    return (
        <div className={styles.wrapper}>

            {data.map((item, i) => (
                    <div key={item.question + item.answer} className={styles.item}>
                        <div className={styles.title}>
                            <h5 className={styles.heading}>{item.question}</h5>
            <span className={selected === i ? styles.buttonActive : styles.button} onClick={() => toggle(i)}>{selected === i ? 'Hide' : 'Show' }</span>
                        </div>
                        <div className={selected === i ? styles.showText : styles.text}>{item.answer}</div>
                    </div>
                )
            )
            }
        </div>
    )
}

const data = [
    {
        answer: 'At ForaPay, we prioritize the security and protection of your financial information. We employ state-of-the-art security measures to ensure the safety of your transactions and data. Our advanced encryption technology safeguards your personal and financial details, providing you with a secure banking environment. Rest assured that your money and sensitive information are in safe hands with ForaPay.',
         question: 'How Safe is ForaPay?'
    },
    {
        answer: 'To send money through ForaPay, we require a simple verification process to ensure the security of your transactions. Depending on the amount and destination of the transfer, you may need to provide basic identification documents such as a valid government-issued ID or passport. Our verification process is designed to comply with regulatory requirements and protect against fraud and unauthorized transactions.',
        question: 'What verification do I need to send money?'
    },
    {
        answer: 'The time it takes for your money to arrive depends on several factors, including the destination country and the chosen payment system. For domestic transfers within Canada, funds are typically delivered within the same business day. International transfers may take slightly longer due to currency conversion and processing times. Rest assured, ForaPay strives to provide efficient and timely money transfers, and we continually optimize our systems to ensure speedy delivery of your funds.',
         question: 'How long will it take for my money to arrive?'
    },
    {
        answer: 'With ForaPay, you can easily send money to over 130 countries worldwide. Our extensive network of global partners enables us to provide reliable and efficient money transfer services to a wide range of destinations. Whether you need to support your loved ones, conduct international business transactions, or facilitate cross-border payments, ForaPay offers you a convenient and secure solution for sending money globally.',
         question: 'Send money to over 130 countries with ForaPay'
    },
    {
        answer: 'Getting started with ForaPay is quick and easy. Simply visit our website or download our mobile app, and follow the straightforward registration process. Create your personal or business account, provide the required information, and complete the necessary verification steps. Once your account is set up, you can begin exploring the diverse range of banking services and enjoying the benefits of ForaPay\'s global financial super-app.',
         question: 'Getting started with ForaPay'
    }, {
        answer: 'At ForaPay, we prioritize your online safety and provide comprehensive measures to protect you from online threats. We offer a dedicated security guide that provides valuable tips and best practices for staying safe while banking online. This guide covers topics such as creating strong passwords, recognizing phishing attempts, securing your devices, and safeguarding your personal information. By following our security guide, you can enhance your online safety and enjoy a worry-free banking experience with ForaPay.',
         question: 'How to stay safe online - our security guide'
    }
]

export default QuestVer2;