import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import Form from "./Form"
import {useNavigate} from "react-router-dom";

const LoginPage = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const isNonMobileScreen = useMediaQuery("(min-width: 1000px)");

    const primaryLight = theme.palette.primary.light;

    return (
        <Box>
            <Box
                width="100%"
                backgroundColor={theme.palette.background.alt}
                p="1rem 6%"
                textAlign="center"
            >
                <Typography
                    fontWeight="bold"
                    fontSize="32px"
                    color="primary"
                    onClick={() => navigate('/')}
                    sx={{
                        "&:hover": {
                            color: primaryLight,
                            cursor: "pointer",
                        },
                    }}
                >
                    ForaPay
                </Typography>
            </Box>
            <Box
                width={isNonMobileScreen ? "50%" : "93%"}
                maxWidth="500px"
                p="3rem"
                m="2rem auto"
                borderRadius="1.5rem"
                textAlign="center"
            >
                <Form />
            </Box>
        </Box>
    )
}

export default LoginPage;