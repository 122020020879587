export const state = {
  pages: {
    home: {
      promo: {
        title: "Digital payments for your matters",
        text: "We are working with personal and business clients around the World as a licensed digital Fintech company (e.g. digital platform)",
        img: "/Images/Digital Wallet.svg",
      },
      benefits: {
        benefit1: {
          title: "Get it Fast",
          text: "All your money transfers are done in minutes",
          img: "/fust.svg",
          textBack:
            "Our platform is built with speed and convenience in mind. With just a few clicks, you can send money to anyone, anywhere in the world, and the funds will be available for the recipient to use in minutes. Plus, our competitive exchange rates and low fees mean that you get more for your money.",
        },
        benefit2: {
          title: "Get it Safe",
          text: "We use advanced IT technology to protect your money and personal information",
          img: "/Safe.svg",
          textBack:
            "Our platform is built with top-of-the-line security features, including encryption, firewalls, and multi-factor authentication. This ensures that your data is safe from unauthorized access and that your transactions are secure.",
        },
        benefit3: {
          title: "Get it Affordable",
          text: "We offer a range of financial services that are designed to be affordable for everyone",
          img: "/LowPrice.svg",
          textBack:
            "With our services, you won't have to worry about hidden fees or high account minimums. We offer competitive rates and low fees that make it easy to manage your finances without breaking the bank.",
        },
      },
      infoCards: {
        infoCardCurrency: {
          title: "Currency exchange",
          text: "Experience the convenience of buying and selling currency online with ForaPay. Take advantage of the features when utilizing our currency exchange services.",
          img: "/Images/Currency exchange.svg",
        },
        infoCardTransfers: {
          title: "Global money transfers",
          text: "Make domestic and international wire transfers to any financial account, service payments and cash withdrawal in ForaPay services via SWIFT, SEPA and Target 2 systems.",
          img: "/Images/Payments via SWIFT.svg",
        },
        infoCardBoarding: {
          title: "Account Opening with ForaPay",
          text: "Open your personal or business accounts with ease at ForaPay. We prioritize a smooth and hassle-free onboarding experience.",
          img: "/Images/AccountOpening.svg",
        },
        // infoCardDebit: {
        //   title: "ForaPay Debit card",
        //   text: "A functional card for every day. Our personal manager will help you find a Special Deal just for you.",
        //   img: "",
        // },
        infoCardAcquiring: {
          title: "Debit and Business Cards",
          text: "ForaPay offers secure and efficient card acquiring solutions, providing you with card acquiring solutions to cater to your business needs.",
          img: "/Images/ForaPayDebitCard.svg",
        },
        forapayCrypto: {
          title: "ForaPay Crypto Service",
          text: "Embrace the future of finance with our cutting-edge cryptocurrency services. ForaPay offers a seamless way to buy, sell, and transfer cryptocurrencies securely.",
          img: "/Images/Cryptocurrency exchange.svg",
        },
      },
      managing: {
        title: "Managing money transfers in global markets",
        text1: "We are building the world’s first truly global financial super-app. In 2021, ForaPay launched in Canada offering money transfer and currency exchange.",
        text2: "Across our personal and business accounts, we help customers improve their financial wellness, give them more control, and connect people seamlessly across the world.",
      },
      form: {
        title: "Get an answer in clicks",
        text: "Enter your details. These four short questions will show if you qualify to get an account with us.",
        img: "/Images/Form.png",
      },
      questions: {
        question1: {
          answer:
            "We are required by law to verify all of our customers. The type of verification we will need can be different depending on the amount that you are sending, and which country you are sending from. This is because each country you can send from will have different laws that ForaPay need to abide by. Usually, we will only be required to check your name, address and date of birth. However, in some circumstances, we may also require further information in order to comply with our regulatory obligations. This can include the purpose of your transfer or source of funds for example. We will contact you if we require anything from you, so please ensure you check your emails regularly after you’ve created your transfer.",
          question: "How Safe is ForaPay?",
        },
        question2: {
          answer:
            "We are required by law to verify all of our customers. The type of verification we will need can be different depending on the amount that you are sending, and which country you are sending from. This is because each country you can send from will have different laws that ForaPay need to abide by. Usually, we will only be required to check your name, address and date of birth. However, in some circumstances, we may also require further information in order to comply with our regulatory obligations. This can include the purpose of your transfer or source of funds for example. We will contact you if we require anything from you, so please ensure you check your emails regularly after you’ve created your transfer.",
          question: "What verification do I need to send money?",
        },
        question3: {
          answer:
            "We are required by law to verify all of our customers. The type of verification we will need can be different depending on the amount that you are sending, and which country you are sending from. This is because each country you can send from will have different laws that ForaPay need to abide by. Usually, we will only be required to check your name, address and date of birth. However, in some circumstances, we may also require further information in order to comply with our regulatory obligations. This can include the purpose of your transfer or source of funds for example. We will contact you if we require anything from you, so please ensure you check your emails regularly after you’ve created your transfer.",
          question: "How long will it take for my money to arrive?",
        },
        question4: {
          answer:
            "We are required by law to verify all of our customers. The type of verification we will need can be different depending on the amount that you are sending, and which country you are sending from. This is because each country you can send from will have different laws that ForaPay need to abide by. Usually, we will only be required to check your name, address and date of birth. However, in some circumstances, we may also require further information in order to comply with our regulatory obligations. This can include the purpose of your transfer or source of funds for example. We will contact you if we require anything from you, so please ensure you check your emails regularly after you’ve created your transfer.",
          question: "Send money to over 130 countries with ForaPay",
        },
        question5: {
          answer:
            "We are required by law to verify all of our customers. The type of verification we will need can be different depending on the amount that you are sending, and which country you are sending from. This is because each country you can send from will have different laws that ForaPay need to abide by. Usually, we will only be required to check your name, address and date of birth. However, in some circumstances, we may also require further information in order to comply with our regulatory obligations. This can include the purpose of your transfer or source of funds for example. We will contact you if we require anything from you, so please ensure you check your emails regularly after you’ve created your transfer.",
          question: "Getting started with ForaPay",
        },
        question6: {
          answer:
            "We are required by law to verify all of our customers. The type of verification we will need can be different depending on the amount that you are sending, and which country you are sending from. This is because each country you can send from will have different laws that ForaPay need to abide by. Usually, we will only be required to check your name, address and date of birth. However, in some circumstances, we may also require further information in order to comply with our regulatory obligations. This can include the purpose of your transfer or source of funds for example. We will contact you if we require anything from you, so please ensure you check your emails regularly after you’ve created your transfer.",
          question: "How to stay safe online - our security guide",
        },
      },
    },
    getStarted: {},
    business: {
      promo: {
        title: "Online platform for your business",
        text: "All online, no calls and waiting in lines",
        img: "/Images/Crowdfunding.svg",
      },
      benefits: {
        benefit1: {
          title: "Effortless Fund Management",
          text: "Set the auto-schedule funds, and don't miss the deadlines",
          img: "/clock.svg",
        },
        benefit2: {
          title: "Unlock Global Opportunities",
          text: "Access to a wide range of currencies, including major and exotic options",
          img: "/refresh-ccw.svg",
        },
        benefit3: {
          title: "Faster Invoice Payments",
          text: "Pay your invoices fester and with minimal fees",
          img: "/percentThin.svg",
        },
      },
    },
    personal: {
      promo: {
        title: "For all your personal finance needs",
        text: "Discover the advantages of payments with ForaPay and how our services cater to your everyday finance, payments, and investment requirements.",
        img: "/Images/Online Loan.svg",
      },
      main: {
        everydayBanking: {
          title: "Everyday Transfers",
          text: "At ForaPay, we understand that finance should seamlessly integrate into your lifestyle.",
          img: "Images/everydayBanking.svg",
          meta: {
            meta1: {
              title: "Money transfers",
              text: "Easily send and receive funds to and from your accounts, both within Canada and internationally. With ForaPay, you can initiate transfers with just a few clicks, ensuring quick and secure transactions.",
            },
            meta2: {
              title: "Bay Bills",
              text: "With ForaPay, you can effortlessly pay your bills online, eliminating the need for checks or visiting physical locations. Enjoy the convenience of scheduling recurring payments and receiving electronic notifications.",
            },
            meta3: {
              title: "Interac e-Transfers",
              text: "Send money to friends, family, or anyone else with ease using Interac e-Transfers. ForaPay allows you to securely transfer funds between accounts, providing a fast and convenient way to split expenses, pay back debts, or send gifts.",
            },
          },
        },
        paments: {
          title: "Global Paments",
          text: "ForaPay offers a comprehensive range of payment options, both locally and internationally. We ensure secure and efficient transactions.",
          img: "/Images/payments.svg",
          meta: {
            meta1: {
              title: "Make local payments with ease",
              text: "Pay bills, transfer funds, and make purchases effortlessly within Canada. With ForaPay, you can enjoy instant and secure transactions, saving you time and providing peace of mind.",
            },
            meta2: {
              title: "Simplify International Transactions",
              text: "ForaPay simplifies global money transfers, enabling you to send and receive funds across borders. Benefit from competitive exchange rates, low fees, and fast processing times, making international payments hassle-free.",
            },
            meta3: {
              title: "Enhanced Security",
              text: "At ForaPay, the security of your transactions is our utmost priority. Our advanced encryption and fraud detection systems protect your sensitive information, ensuring safe and reliable payment experiences.",
            },
          },
        },
        investments: {
          title: "Investments",
          text: "Start building your financial future with ForaPay's diverse range of saving accounts. Our investment options offer numerous advantages",
          img: "/Images/investments.svg",
          meta: {
            meta1: {
              title: "Competitive interest rates",
              text: "Maximize your savings with ForaPay's attractive interest rates. Watch your money grow faster and achieve your financial goals sooner.",
            },
            meta2: {
              title: "Flexible account options",
              text: "Choose from a variety of saving accounts tailored to meet your specific needs. Whether you're saving for a short-term goal or planning for retirement, ForaPay has the right account for you.",
            },
            meta3: {
              title: "Convenient account management",
              text: "Monitor and manage your saving accounts effortlessly through our online platform. Stay updated on your balances, track your interest earnings, and make deposits or withdrawals conveniently.",
            },
          },
        },
      },
    },
    aboutUs: {
      promo: {
        title: "Who we are and what we aim",
        text: "",
        img: "/Images/Digital Financial.svg",
      },
      howWeAre:
        "ForaPay is a company building better commerce solutions at the online platform market. We've set forth drew a clear mission to perform a universal worldwide financial platform with an innovative approach to global money transferring. Convenient finance, fast transactions, and currency exchange - equip your comfort in finances.",
      ourAim: {
        title: "From the very beginning,",
        text: "the quality of our products has the top priority, what contributed to creating the best service you have ever used. ForaPay has been starting as a start-up and has grown into a major player in the online platform field. And to stay the best, our teams keep doing a great job pushing freedom and comfort into your experience.",
      },
      partners: {
        title: "Our Partners",
        text: "We've partnered with Misolla Ligal Company to bring you a secure product that can be used worldwide. We’re backed by established investors.",
        logos: {
          misolla: "/Logo_misolla.svg",
          mc: "/Mastercard-logo.svg.png",
          visa: "/Visa_Inc._logo.svg.png",
        },
      },
      bgImg: {
        img1: "Images/ovalPink.svg",
        img2: "/Images/ovalGreen.svg",
      },
      ourTeam: {
        person1: {
          name: "Dmitry Lukianov",
          role: "CEO & Founder",
          img: "/Images/Screen Shot 2023-05-11 at 12.51.56 PM.png",
        },
        person2: {
          name: "Yulia Petrosyan",
          role: "Investor Director",
          img: "/Images/Screen Shot 2023-05-25 at 6.20.28 PM.png",
        },
        person3: {
          name: "Anton Filippov",
          role: "CTO",
          img: "/Images/Screen Shot 2023-05-25 at 6.19.51 PM.png",
        },
      },
    },
  },
};
