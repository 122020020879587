import styles from "./products.module.css";
import Footer from "../../components/Footer";
import Product_promo from "../../components/Product_promo/product_promo";
import NavBar from "../../components/Navigation/NavBar";
import Promo from "../../components/Promo/Promo";
import { Typography } from "@mui/material";

const ProductsPage = () => {
    return (
      <>
        <head>
          <title>ForaPay | Get Started</title>
          <meta name="description" content="Generated by create next app" />
          <link
            rel="icon"
            href="/fora-pay-ca-finalisation/public/favicon.ico"
          />
          <base href="/" />
        </head>
        <div className={styles.container}>
          <div className={styles.main}>
            <div className={styles.block1}>
              <NavBar className={styles.navbar} />
              <Promo
                className={styles.promo}
                title={"Get best services with no hidden fees"}
                text={""}
                img={"/Images/Financial Credit.svg"}
              />
            </div>
            <div className={styles.block3} id={"block2"}>
              <div className={styles.block3_content}>
              <Typography textAlign="center" variant="h1" mt="4rem" mb="2rem">
                  Choose your plan
                </Typography>
                <div className={styles.main_products}>
                  <div className={styles.productCard}>
                    <Product_promo
                      title="Fpay Personal"
                      image="/Images/ForaPay Business card.svg"
                      flexDirection="column"
                      fullWidth
                    />
                  </div>
                  <div className={styles.productCard}>
                    <Product_promo
                      title="Fpay Business"
                      image="/Images/ForaPayDebitCard.svg"
                      flexDirection="column"
                      fullWidth
                    />
                  </div>
                  <div className={styles.productCard}>
                    <Product_promo
                      title="Fpay Platinum"
                      image="/Images/ForaPay Business card.svg"
                      flexDirection="column"
                      fullWidth
                    />
                  </div>
                  <div>
                    <br />
                  </div>
                </div>
                <div className={styles.specialDeal}>
                <Typography textAlign="center" variant="h1" mt="4rem" mb="2rem">
                    Our special offer for your business
                  </Typography>
                  <div className={styles.productCard}>
                    <Product_promo
                      title="Fpay Business"
                      image="/Images/ForaPayDebitCard.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer image="/logoSmall.svg" />
        </div>
      </>
    );
}

export default ProductsPage;