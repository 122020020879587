import styles from "./business.module.css";
import Benefit from "../../components/Benefit";
import Footer from "../../components/Footer";
import Product_promo from "../../components/Product_promo/product_promo";
import NavBar from "../../components/Navigation/NavBar";
import Promo from "../../components/Promo/Promo";
import { state } from "../../projectData/state";
import { Box, Typography } from "@mui/material";

const props = state.pages.business;

const BusinessPage = () => {
  return (
    <>
      <head>
        <title>ForaPay | Business</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="icon" href="/fora-pay-ca-finalisation/public/favicon.ico" />
        <base href="/" />
      </head>
      <div className={styles.container}>
        <div className={styles.main}>
          <div className={styles.block1}>
            <NavBar className={styles.navbar} />
            <Promo
              title={props.promo.title}
              text={props.promo.text}
              img={props.promo.img}
            />
          </div>

            <div className={styles.block2_content}>
              <Typography textAlign="center" variant="h1">
                Open your business right from your home
              </Typography>
              <div className={styles.contentContainer}>
                <div className={styles.mainContent}>
                  <Typography textAlign="left" variant="h3" m="1rem 0" >
                    Best Solutions for Your Business.
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "30px",
                      textAlign: "center",
                      backgroundColor: "#FFFFFF",
                      borderRadius: "50px",
                      boxShadow: "0 4px 16px rgba(1, 17, 17, 0.25)",
                      padding: "20px",

                    }}
                  >
                    
                      <li className={styles.listItem}>
                        Get your Business Payment using Fora app
                      </li>
                      <li className={styles.listItem}>QR-codes for payments</li>
                      <li className={styles.listItem}>
                        Create social profile of your business for direct
                        transfers
                      </li>
                    
                  </Box>
                  <Box 
                    mt="2rem"
                  >
                    <img src="/Group230.svg" alt="Icon" 
                    width="100%"
                    height="100%"
                    />
                  </Box>
                </div>
                <div className={styles.appPhoto}>
                  <img src="/Phone.svg" alt="Phone image" />
                </div>
              </div>
            </div>
          
          <div className={styles.block3}>
            <div className={styles.block3_content}>
              <Typography textAlign="center" variant="h1" m="3rem 0">
                Use business card just like debit
              </Typography>
              <div className={styles.productCard}>
                <Product_promo
                  title="Fpay Platinum"
                  image="/Images/ForaPayDebitCard.svg"
                />
              </div>
            </div>
          </div>
          <div className={styles.block4}>
            <div className={styles.block4_content}>
              <Typography textAlign="center" variant="h1">
                Pay international bills, vendor and employee bills easily
              </Typography>
              <div className={styles.mainContent}>
                <div className={styles.meta}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: "367px",
                      maxHeight: "389px",
                      rowGap: "30px",
                      textAlign: "center",
                      backgroundColor: "#FFFFFF",
                      borderRadius: "50px",
                      boxShadow: "0 4px 16px rgba(1, 17, 17, 0.25)",
                      marginTop: "68px",
                      padding: "20px",
                    }}
                  >
                    <Box mt="50px">
                      <img src={props.benefits.benefit1.img} />
                    </Box>
                    <Box>
                      <Typography variant="h2">
                        {props.benefits.benefit1.title}
                      </Typography>
                      <Typography variant="h5" mt="30px" mb="30px">
                        {props.benefits.benefit1.text}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: "367px",
                      maxHeight: "389px",
                      rowGap: "30px",
                      textAlign: "center",
                      backgroundColor: "#FFFFFF",
                      borderRadius: "50px",
                      boxShadow: "0 4px 16px rgba(1, 17, 17, 0.25)",
                      marginTop: "68px",
                      padding: "20px",
                    }}
                  >
                    <Box mt="50px">
                      <img src={props.benefits.benefit2.img} />
                    </Box>
                    <Box>
                      <Typography variant="h2">
                        {props.benefits.benefit2.title}
                      </Typography>
                      <Typography variant="h5" mt="30px" mb="30px">
                        {props.benefits.benefit2.text}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: "367px",
                      maxHeight: "389px",
                      rowGap: "30px",
                      textAlign: "center",
                      backgroundColor: "#FFFFFF",
                      borderRadius: "50px",
                      boxShadow: "0 4px 16px rgba(1, 17, 17, 0.25)",
                      marginTop: "68px",
                      padding: "20px",
                    }}
                  >
                    <Box mt="50px">
                      <img src={props.benefits.benefit3.img} />
                    </Box>
                    <Box>
                      <Typography variant="h2">
                        {props.benefits.benefit3.title}
                      </Typography>
                      <Typography variant="h5" mt="30px" mb="30px">
                        {props.benefits.benefit3.text}
                      </Typography>
                    </Box>
                  </Box>
                </div>
                <div>
                  <a href="/products" className={styles.learn_bnt}>
                    Learn More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer image="/logoSmall.svg" />
      </div>
    </>
  );
};

export default BusinessPage;
