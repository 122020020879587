import styles from "./promo.module.css";
import {Box, Typography, useMediaQuery} from "@mui/material";

const Promo = (props) => {
    return(
        <div className={styles.promo}>
            <div className={styles.promo_content}>
                <div className={styles.title}>
                    {props.title}
                </div>
                <div className={styles.promo_text}>
                    {props.text}
                </div>
                <a className={styles.learn_bnt} href="/#block2">Learn More</a>
            </div>
            <Box
                display="flex"
                justifyContent="center"
            >
                <img src={props.img} alt="Currency exchange" style={
                    {
                        maxWidth: '100%',
                        height: "auto",
                        padding: "20px",
                    }
                }/>
            </Box>
        </div>
    );
}

export default Promo;
